.order-same-detaul h6 {
    font-size: 14px;
    color: #000;
  }
  .order-same-detaul h6 span {
    color: #c99b11;
    font-size: 13px;
  }
  th.order-table-new {
    font-size: 15px !important;
    font-weight: 500;
  }
  .payment-methode p.mb-1 {
    font-size: 14px;
    padding: 5px 0px;
  }
  h3.main-text-oder-details {
    font-size: 14px;
    font-weight: 600;
  }
  .payment-methode span.text-muted {
    font-size: 15px;
    font-weight: 500;
    color: #000 !important;
  }
 .text-muted span{
    font-weight: 600;
    color: grey;
    font-size: 16px;
  } 
  /* by ad  */
  .text-muted-span{
    font-weight: 600;
    color: grey;
    font-size: 16px;
  }
  /* // */
  .order-currency h6 {
    font-size: 14px;
  }
  .order-currency p {
    font-size: 14px;
  }
  .order-currency1 h6 {
    font-size: 14px;
  }
  .order-currency1 p {
    font-size: 14px;
  }
  .order-currency2 h6 {
    font-size: 14px;
  }
  .order-currency2 p {
    font-size: 14px;
  }
  .order-currency2 {
    text-align: right;
  }
  
  h6.order-bttn {
    font-size: 16px;
  }
  .order-img-new img {
    width: 100px;
    height: 100px;
  }
  .order-new-site {
    display: flex;
  }
  
  .order-new-site-1 p {
    line-height: 18px;
  }
  td.order-tt {
    font-size: 14px;
    color: #777;
  }
  .payment-methode.new-1 {
    text-align: right;
  }

  /* -------------------- */




  @media(max-width:1024px){
    .card.order-details {
      width: 100%;
  }
  }
  
  @media(max-width:991px){
    .card.order-details {
      padding: 10px 20px 40px 20px;
   
  }
  }
  
  @media(max-width:767px){
  
    .payment-methode.new-1 {
      text-align: left;
      padding-top: 18px;
  }
  .payment-methode.new {
    text-align: right;
  }
  .payment-methode p.mb-1 {
    font-size: 14px;
    padding: 5px 0px;
    line-height: 14px;
  }
  }
  
  @media(max-width:575px){
    .payment-methode.new {
      text-align: right;
  }
  }
  @media(max-width:425px){
    .payment-methode.new {
      text-align: left;
      padding-top: 22px;
  }
  h3.order-details {
    font-size: 16px;
  }
  }
  


  /* ----------------------- order listing --------------- table css */

  .single_line_header{
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any overflow content */
    text-overflow: ellipsis; /* Show an ellipsis (...) when content overflows */

  }
  