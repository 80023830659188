
/* .swiper {
  width: 100%;
  height: 100%;
}
.swiper-button-next, .swiper-button-prev{
  color:#121212;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 35%;
  width: 100%;
  
}

.mySwiper {
  height: 15%;
  box-sizing: border-box;
  padding: 10px 0;
  
}

.mySwiper .swiper-slide {
  width: 50%;
  height: 100%;
  opacity: 0.9;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* .mySwiper {
  height: 35%;
  box-sizing: border-box;
  padding: 10px 0;
  
}

.mySwiper .swiper-slide {
  width: 50%;
  height: 100%;
  opacity: 0.9;
} */

.swiper-button-next, .swiper-button-prev{
  color:#121212;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide .react-player {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ant-image-preview-img {
    position: absolute !important;
    left: 40% !important;
    top:20% !important;
    height: 300px;
}
.ant-image-preview-wrap {
    z-index: 9999;
}


.paginationnew ul {
    display: flex;
    /* width: 100%; */
    /* align-items: center; */
    justify-content: center;
    position: relative;
    /* text-align: center; */
    list-style-type: none;
    /* padding: 10px; */
}
.paginationnew ul li {
    padding: 0px 13px;
}


.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
      border-radius: 50%;
    background-color: #00AB55 ;
    border-color: #00AB55 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #00AB55 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #00AB55 ;
    border-color: #00AB55;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #00AB55
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
  .pay-b {
    /* position: absolute; */
    background: #0078d7;
    color: white;
    top: 15px;
    left: 1px;
    width: 100%;
    position: relative;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
}